import React from "react";
import SOS from "../Images/SOS.png";
import "../App.css";
import { Box, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Pop from "./Pop";
import Log from "./Log";
import Search from "./SearchIcon";
import useIsMobile from "./MobileQuery";
import AddDetailPhone from "./AddDetailPhone";

const Header: React.FC = () => {
  const mobile = useIsMobile();
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            gap: "15px",
            paddingLeft: "15px",
            paddingTop: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ width: "35px", height: "35px" }}
              src={SOS}
              alt="logo"
              className="logo"
            />
            <Typography
              sx={{ fontSize: "20px", fontWeight: "550", paddingLeft: "10px" }}
            >
              <span style={{ fontSize: mobile ? "16px" : "1.25rem" }}>
                Business Directory
              </span>
            </Typography>
          </div>

          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              gap: "15px",
              alignItems: "center",
              paddingRight: mobile? "0":"15px"
            }}
          >
            {/* <AddCircleIcon sx={{ fontSize: "32px" }} /> */}
            <Search />
            {/* <Pop /> */}
            {mobile ? <AddDetailPhone /> : <Pop />}

            <NotificationsIcon
              sx={{
                height: "28px",
                width: "28px",
                display: mobile ? "none" : "inherit",
              }}
            />
            <Log />
            {/* <AccountCircleIcon sx={{ fontSize: "32px" }} /> */}
          </Box>
        </div>
      </Box>
    </>
  );
};

export default Header;
