import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Dataform from './Dataform';
import OcrImage from './OcrImage';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BasicTabs(props: { handleClose: () => void }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [isOcrDataUploaded, setIsOcrDataUploaded] = React.useState<Boolean>(false);


  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" indicatorColor="primary">
          <Tab label="Form" {...a11yProps(0)} />
          <Tab label="DOCS" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
         <Dataform handleClose={props.handleClose} setIsOcrDataUploaded = {setIsOcrDataUploaded} /> {/*extract props */}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <OcrImage isOcrDataUploaded = {isOcrDataUploaded} />
      </CustomTabPanel>
    </Box>
  );
}

export default function AddDetailPhone() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <AddCircleIcon onClick={handleClickOpen} sx={{ height: '28px', width: '28px' }} />
      <Drawer
        anchor="bottom"
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiDrawer-paper': { padding: '16px', height: 'auto' } }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box component="h2" sx={{ margin: 0 }}>
            Add
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <BasicTabs handleClose={handleClose} />
      </Drawer>
    </React.Fragment>
  );
}

