import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
// import Dropdown from './Sectordrop';
// import DeleteEntry from "./DeleteEntry";
// import Entry from "./Entry";

import axios from "axios";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { toast } from "react-toastify";
import Typography from "@mui/material/Typography";
import Entry from "./Entry";
import { DataContext } from "../Context/AllContext";
import { headers } from "../Layout/Layout";

export default function Table2(props: {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { data } = React.useContext(DataContext);

  const [editOpen, setEditOpen] = React.useState(false);

  const [editInfo, setEditInfo] = React.useState<GridRowParams<any>>();

  const [rowCount, setRowCount] = React.useState<number>();

  const SimplifiedEntryRows = data.map(
    (item: {
      id: number;
      attributes: { 
        subject_area: String;
        expertise: String;
        Department: string;
        Designation: string;
        Organization: string;
        Sector: string;
        Weblink: string;
        Referrer: string;
      };
    }) => ({
      id: item.id,
      Subject_Area: item.attributes.subject_area,
      Expertise: item.attributes.expertise,
      Department: item.attributes.Department,
      Designation: item.attributes.Designation,
      Organization: item.attributes.Organization,
      Sector: item.attributes.Sector,
      URL: item.attributes.Weblink,
      Referrer: item.attributes.Referrer,
    })
  );
  const getColumnWidth = (rows: any[], field: string, headerText: string) => {
    const maxLength = Math.max(
      ...rows.map((row) => (row[field] ? row[field].toString().length : 0)),
      headerText.length
    );
    return field === "URL" ? maxLength * 7 : maxLength * 10;
  };

  const getRowCount = () => {
    axios
      .get(
        `${process.env.REACT_APP_STRAPIURL}/api/entries?pagination[pageSize]=0&pagination[page]=0`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setRowCount(res.data.meta.pagination.total);
      })
      .catch(() => {
        toast.error("Failed to fetch data", {
          position: "bottom-left",
        });
      });
  };

  React.useEffect(() => {
    getRowCount();
  }, []);

  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID',  width:90
    // },
    {
      field: "SubjectArea",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Subject Area
          </Typography>
        );
      },
      // minWidth: 150,
      headerAlign: "left",
      align: "left",
      // flex: 1,
      sortable: false,
      width: getColumnWidth(
        SimplifiedEntryRows,
        "Subject_Area",
        "Subject Area"
      ),
      renderCell: (row) => {
        return row.row.attributes.subject_area? row.row.attributes.subject_area:"-";
      },
    },
    {
      field: "Expertise",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Expertise
          </Typography>
        );
      },
      type: "string",
      // minWidth: 150,
      headerAlign: "left",
      align: "left",
      width: getColumnWidth(SimplifiedEntryRows, "Expertise", "Expertise"),
      sortable: false,
      // flex: 1,
      renderCell: (row) => {
        return Array.isArray(row.row.attributes.expertise)
          ? row?.row?.attributes?.expertise?.map(
              (value: string, index: number) => {
                return index + 1 === row.row.attributes.expertise.length
                  ? value
                  : `${value}, `;
              }
            )
          : "-";
      },
    },

    {
      field: "designation",
      // headerName: "Designation ",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Designation
          </Typography>
        );
      },
      // minWidth: 150,
      sortable: false,
      width: getColumnWidth(SimplifiedEntryRows, "Designation", "Designation"),
      headerAlign: "left",
      align: "left",
      // flex: 1,
      renderCell: (row) => {
        return row.row.attributes.Designation ? row.row.attributes.Designation : "-" ;
      },
    },
    {
      field: "department",
      // headerName: "Department",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Department
          </Typography>
        );
      },
      // minWidth: 150,
      sortable: false,
      headerAlign: "left",
      width: getColumnWidth(SimplifiedEntryRows, "Department", "Department"),
      align: "left",
      renderCell: (row) => {
        return row.row.attributes.Department ? row.row.attributes.Department : "-";
      },
    },
    {
      field: "organization",
      // headerName: "Organization",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Organization
          </Typography>
        );
      },
      type: "number",
      sortable: false,
      // minWidth: 150,
      width: getColumnWidth(
        SimplifiedEntryRows,
        "Organization",
        "Organization"
      ),
      headerAlign: "left",
      align: "left",
      renderCell: (row) => {
        return row.row.attributes.Organization ? row.row.attributes.Organization : "-";
      },
    },
    {
      field: "sector",
      // headerName: "Sector",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Sector
          </Typography>
        );
      },
      sortable: false,
      // minWidth: 150,
      headerAlign: "left",
      width: getColumnWidth(SimplifiedEntryRows, "Sector", "Sector"),
      align: "left",
      renderCell: (row) => {
        return row.row.attributes.Sector ? row.row.attributes.Sector : "-";
      },
    },
    {
      field: "URL",
      // headerName: "URL",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            URL
          </Typography>
        );
      },
      sortable: false,
      // minWidth: 300,
      width: getColumnWidth(SimplifiedEntryRows, "URL", "URL"),
      headerAlign: "left",
      align: "left",
      renderCell: (row) => {
        return (
          row.row.attributes.Weblink ?
          <a
            href={row.row.attributes.Weblink}
            target="blank"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            {row.row.attributes.Weblink}
          </a>
          :"-"
        );
      },
    },
    {
      field: "referrer",
      // headerName: "Referrer",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Referrer
          </Typography>
        );
      },
      sortable: false,
      // width: 150,
      width: getColumnWidth(SimplifiedEntryRows, "Referrer", "Referrer"),
      headerAlign: "left",
      align: "left",
      renderCell: (row) => {
        return row.row.attributes.Referrer ? row.row.attributes.Referrer : "-";
      },
    },
    // {
    //   field: "icon",
    //   headerName: "",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 100,
    //   renderCell: (params) => (
    //     <Box sx={{ fontSize: "small" }} display="flex">
    //       <Entry params={params} />
    //       <DeleteEntry params={params} />
    //     </Box>
    //   ),
    // },
  ];

  return (
    <>
      <Box sx={{ height: "calc(100dvh - 113px)", flex: 1, overflow: "hidden" }}>
        <DataGrid
          rows={data}
          paginationMode="server"
          rowCount={rowCount}
          apiRef={props.apiRef}
          scrollbarSize={0}
          columns={columns}
          disableRowSelectionOnClick
          getRowId={(row) => row?.id}
          disableColumnMenu
          onRowClick={(params) => {
            setEditOpen(true);
            setEditInfo(params.row);
          }}
          sx={{
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-scrollbar--vertical": {
              "&::-webkit-scrollbar": {
                display: "none",
              },
            },
            "& .MuiDataGrid-scrollbar--horizontal": {
              "&::-webkit-scrollbar": {
                height: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "1px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1",
              },
            },
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
            "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
              borderLeft: "none", // Remove the middle border between the pagination text and page selector
            },
          }}
          onPaginationModelChange={(params) => {
            props.setPage(params.page);
            props.setPageSize(params.pageSize);
          }}
          pageSizeOptions={[25, 50, 75, 100]}
          paginationModel={{
            page: props.page,
            pageSize: props.pageSize,
          }}
        />
      </Box>

      <Entry params={editInfo} open={editOpen} setOpen={setEditOpen} />
    </>
  );
}
