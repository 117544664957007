import * as React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
import ComboBox from "./Auto";
import Sectordrop from "./Sectordrop";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import DeleteEntry from "./DeleteEntry";
import Expertise from "./Expertise";
import { MobileCode } from "./MobileCode";
import EntryProfile from "./EntryProfile";
// import ProfileImage from "./ProfileText";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import ExpertiseField from "./ExpertiseField";
import { useSearchParams } from "react-router-dom";
import useIsMobile from "./MobileQuery";
import {
  DataContext,
  GridContext,
  LoadingContext,
} from "../Context/AllContext";
import { headers } from "../Layout/Layout";
export const removeBorder = {
  flex: 1,
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};

export default function Entry(props: {
  params: any;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  // const { getEntries } = React.useContext(DataContext);
  const { getGridData } = React.useContext(GridContext);
  const { setLoading } = React.useContext(LoadingContext);
  const { getListData } = React.useContext(DataContext);

  const [contact, setContact] = React.useState(
    props.params?.attributes.full_name
  );
  const [lastName, setLastName] = React.useState(
    props.params?.attributes.last_name
  );
  const [subject, setSubject] = React.useState(
    props.params?.attributes.subject_area
  );
  const [expertise, setExpertise] = React.useState(
    props.params?.attributes.expertise
  );
  const [phone, setPhone] = React.useState(props.params?.attributes.Phone);
  const [editedPhone, setEditedPhone] = React.useState(
    props.params?.attributes.Phone
  );

  const [email, setEmail] = React.useState(props.params?.attributes.Email);
  const [designation, setDesignation] = React.useState(
    props.params?.attributes.Designation
  );
  const [department, setDepartment] = React.useState(
    props.params?.attributes.Department
  );
  const [organization, setOrganization] = React.useState(
    props.params?.attributes.Organization
  );
  const [sector, setSector] = React.useState(props.params?.attributes.Sector);
  const [weblink, setWeblink] = React.useState(
    props.params?.attributes.Weblink
  );
  const [referrer, setReferrer] = React.useState(
    props.params?.attributes.Referrer
  );
  const [image, setImage] = React.useState(
    props.params?.attributes?.Profile_Image?.data
  );
  React.useEffect(() => {
    setContact(props.params?.attributes.full_name);
    setLastName(props.params?.attributes.last_name);
    setSubject(props.params?.attributes.subject_area);
    setExpertise(props.params?.attributes.expertise);
    setPhone(props.params?.attributes.Phone);
    setEditedPhone(props.params?.attributes.Phone);
    setEmail(props.params?.attributes.Email);
    setDesignation(props.params?.attributes.Designation);
    setDepartment(props.params?.attributes.Department);
    setOrganization(props.params?.attributes.Organization);
    setSector(props.params?.attributes.Sector);
    setWeblink(props.params?.attributes.Weblink);
    setReferrer(props.params?.attributes.Referrer);
    setImage(props.params?.attributes?.Profile_Image?.data);
  }, [props.params]);

  const [errors, setErrors] = React.useState({
    contact: "",
    lastName: "",
    email: "",
    designation: "",
    department: "",
    organization: "",
    weblink: "",
    referrer: "",
    phone: "",
    expertise: "",
  });

  const validate = (name: string, value: string) => {
    let error = "";
    if (
      ((name === "contact" || name === "lastName") && !value.trim()) ||
      (name === "phone" && !value?.split(/ (.+)/)[1]?.trim())
    ) {
      error = "This field is required.";
    } else if (
      [
        "contact",
        "lastName",
        "designation",
        "department",
        "organization",
        "referrer",
      ].includes(name) &&
      /\d/.test(value)
    ) {
      error = "Invalid input, no numbers allowed.";
    } else if (
      value !== "" &&
      name === "email" &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    ) {
      error = "Invalid email format.";
    } else if (
      name === "phone" &&
      !/^\D*(?:\d\D*){10}$/.test(value?.split(/ (.+)/)[1])
    ) {
      error = "Invalid phone number.";
    } else if (
      value !== "" &&
      name === "expertise" &&
      !/^\d{1,2}$/.test(value)
    ) {
      error = "Invalid expertise";
    } else if (
      value !== "" &&
      name === "weblink" &&
      !/^(ftp|http|https):\/\/[^ "]+$/.test(value)
    ) {
      error = "Invalid URL format.";
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  interface IEntryData {
    full_name: string | null;
    last_name: string | null;
    sorting_value: string | null;
    subject_area: string | null;
    expertise: string[] | null;
    Phone: string | null;
    Email: string | null;
    Designation: string | null;
    Department: string | null;
    Organization: string | null;
    Sector: string | null;
    Weblink: string | null;
    Referrer: string | null;
    Profile_Image?: number;
  }

  const [searchParams] = useSearchParams();

  const tabFromURL = searchParams.get("tab");

  const createImageEntry = (formData: IEntryData) => {
    axios
      .put(
        `${process.env.REACT_APP_STRAPIURL}/api/entries/${props.params?.id}`,
        { data: formData },
        { headers: headers }
      )
      .then((res) => {
        tabFromURL === "1" ? getGridData() : getListData();
        toast.success("Entry Updated successfully", {
          position: "bottom-left",
        });
        props.setOpen(false);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Update failed", {
          position: "bottom-left",
        });
        setLoading(false);
      });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    validate("contact", contact);
    validate("phone", phone);
    validate("lastName", lastName);

    if (
      !contact.trim() ||
      !phone.trim() ||
      !lastName.trim() ||
      errors.contact ||
      errors.phone ||
      errors.lastName
    ) {
      setLoading(false);
      return;
    }

    const imageEntry = new FormData();

    if (image && image.length > 0) {
      imageEntry.append("files", image[0]);
      imageEntry.append("ref", "entry");
      imageEntry.append("field", "Profile_Image");
    }

    const formData: IEntryData = {
      full_name: contact === "" ? null : contact,
      last_name: lastName === "" ? null : lastName,
      sorting_value: contact === "" ? null : contact?.toLowerCase(),
      subject_area: subject?.toLowerCase() === "" ? null : subject,
      expertise: expertise === 0 ? null : expertise,
      Phone: editedPhone === "" ? null : editedPhone,
      Email: email === "" ? null : email,
      Designation: designation === "" ? null : designation,
      Department: department === "" ? null : department,
      Organization: organization === "" ? null : organization,
      Sector: sector?.toLowerCase() === "" ? null : sector,
      Weblink: weblink === "" ? null : weblink,
      Referrer: referrer === "" ? null : referrer,
      Profile_Image: image === null || image.length === 0 ? null : image,
    };

    if (image && image.length > 0) {
      axios
        .post(`${process.env.REACT_APP_STRAPIURL}/api/upload`, imageEntry, {
          headers: headers,
        })
        .then((res) => {
          formData.Profile_Image = res.data[0].id;
          createImageEntry(formData);
        });
    } else {
      createImageEntry(formData);
    }
  };

  //   const handleEdit = (
  //     params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  //   ) => {
  //     console.log(props.params);
  //     // axios
  //     //   .put(`${process.env.REACT_APP_STRAPIURL}/api/entries/${params.id}`)
  //     //   .then((res) => {
  //     //     console.log("Editted successfully")
  //     //   });
  //   };

  const handleClose = () => {
    props.setOpen(false);
    setContact(props.params?.attributes.full_name);
    setLastName(props.params?.attributes.last_name);
    setPhone(props.params?.attributes.Phone);
    setEditedPhone(props.params?.attributes.Phone);
    setExpertise(props.params?.attributes.expertise);
    setEmail(props.params?.attributes.email);
    setDesignation(props.params?.attributes.Designation);
    setDepartment(props.params?.attributes.Department);
    setOrganization(props.params?.attributes.Organization);
    setSector(props.params?.attributes.Sector);
    setWeblink(props.params?.attributes.Weblink);
    setReferrer(props.params?.attributes.Referrer);
    setSubject(props.params?.attributes.subject_area);
    setImage(props.params?.attributes?.Profile_Image?.data);
    setIsEdit(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit(e as React.FormEvent);
      setIsEdit(false);
    }
  };

  const [isEdit, setIsEdit] = React.useState(false);

  const handleEditKey = () => {
    setIsEdit(!isEdit);
  };

  const mobile = useIsMobile();

  return (
    <React.Fragment>
      {/* <EditIcon
        onClick={() => handleClickOpen()}
        sx={{ width: "20px", height: "20px", cursor: "pointer" }}
      /> */}

      <Drawer anchor="bottom" open={props.open} onClose={handleClose}>
        <DialogTitle sx={{ m: 0 }} id="alert-dialog-title">
          {!isEdit ? "Detail" : "Edit"}
          <CloseIcon
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 15,
              color: "gray",
              cursor: "pointer",
            }}
          />
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "0" }}>
          <DialogContentText id="alert-dialog-description">
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 },
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              onKeyDown={handleKeyDown}
            >
              <EntryProfile
                firstName={contact}
                lastName={lastName}
                image={image}
                setImage={setImage}
                isEdit={isEdit}
              />
              {/* <ProfileImage firstName={contact} lastName={lastName} image={image}  /> */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <TextField
                  id="name"
                  name="contact"
                  label="First Name"
                  size="small"
                  variant="outlined"
                  // required
                  sx={{
                    ...(!isEdit ? removeBorder : { flex: 1 }),
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      color: "black",
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  value={contact}
                  onChange={(e) => {
                    setContact(e.target.value);
                    validate("contact", e.target.value);
                  }}
                  error={!!errors.contact}
                  helperText={errors.contact}
                  disabled={!isEdit}
                  InputProps={{
                    style: { fontSize: mobile ? "13px" : "16px" },
                  }}
                />
                <TextField
                  id="name"
                  name="contact"
                  label="Last Name"
                  size="small"
                  // required
                  sx={{
                    ...(!isEdit ? removeBorder : { flex: 1 }),
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      color: "black",
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    validate("lastName", e.target.value);
                  }}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  disabled={!isEdit}
                  InputProps={{
                    style: { fontSize: mobile ? "13px" : "16px" },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                {/* <TextField
                  id="exp"
                  label="Expertise"
                  variant="outlined"
                  value={expertise}
                  size="small"
                  onChange={(e) => {
                    setExpertise(e.target.value);
                    validate("expertise", e.target.value);
                  }}
                  error={!!errors.expertise}
                  helperText={errors.expertise}
                /> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <MobileCode
                    value={phone}
                    // required
                    sx={{
                      ...(!isEdit ? removeBorder : { flex: 1 }),
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black",
                        color: "black",
                      },
                      "& .MuiInputLabel-root.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    onChange={setEditedPhone}
                    validate={validate}
                    error={!!errors.phone}
                    helperText={errors.phone}
                    isEdit={isEdit}
                    disabled={!isEdit}
                  />
                </Box>

                {/* <TextField
                  id="phone"
                  label="Mobile"
                  size="small"
                  variant="outlined"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    validate("phone", e.target.value);
                  }}
                  error={!!errors.phone}
                  helperText={errors.phone}
                /> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    size="small"
                    sx={{
                      ...(!isEdit ? removeBorder : { flex: 1 }),
                      "& .MuiInputBase-input": {
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "150px",
                      },
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black",
                        color: "black",
                      },
                      "& .MuiInputLabel-root.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    value={isEdit ? email || "" : email || "-"}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      validate("email", e.target.value);
                    }}
                    error={!!errors.email}
                    helperText={errors.email}
                    disabled={!isEdit}
                    InputProps={{
                      endAdornment:
                        !isEdit && email ? (
                          <InputAdornment position="start">
                            <a href={`mailto:${email}`}>
                              <EmailIcon
                                sx={{
                                  height: "18px",
                                  width: "18px",
                                  cursor: "pointer",
                                }}
                              />
                            </a>
                          </InputAdornment>
                        ) : null,
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <ComboBox
                  variant="outlined"
                  spacing="100%"
                  setSubject={setSubject}
                  subject={subject}
                  small
                  isEdit={isEdit}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                {isEdit ? (
                  <Expertise
                    variant="outlined"
                    spacing="100%"
                    setSelectedExpertise={setExpertise}
                    selectedExpertise={expertise}
                    small
                    isEdit={isEdit}
                  />
                ) : (
                  <ExpertiseField
                    variant="outlined"
                    spacing="100%"
                    setExpertise={setExpertise}
                    expertise={expertise}
                    small
                    isEdit={isEdit}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <TextField
                  id="designation"
                  label="Designation"
                  size="small"
                  variant="outlined"
                  sx={{
                    ...(!isEdit ? removeBorder : { flex: 1 }),
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      color: "black",
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  value={isEdit ? designation || "" : designation || "-"}
                  onChange={(e) => {
                    setDesignation(e.target.value);
                    validate("designation", e.target.value);
                  }}
                  error={!!errors.designation}
                  helperText={errors.designation}
                  disabled={!isEdit}
                  InputProps={{
                    style: { fontSize: mobile ? "13px" : "16px" },
                  }}
                />
                <TextField
                  id="department"
                  label="Department"
                  size="small"
                  variant="outlined"
                  sx={{
                    ...(!isEdit ? removeBorder : { flex: 1 }),
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      color: "black",
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  value={isEdit ? department || "" : department || "-"}
                  onChange={(e) => {
                    setDepartment(e.target.value);
                    validate("department", e.target.value);
                  }}
                  error={!!errors.department}
                  helperText={errors.department}
                  disabled={!isEdit}
                  InputProps={{
                    style: { fontSize: mobile ? "13px" : "16px" },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <TextField
                  id="organization"
                  label="Organization"
                  size="small"
                  sx={{
                    ...(!isEdit ? removeBorder : { flex: 1 }),
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      color: "black",
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  variant="outlined"
                  value={isEdit ? organization || "" : organization || "-"}
                  onChange={(e) => {
                    setOrganization(e.target.value);
                    validate("organization", e.target.value);
                  }}
                  error={!!errors.organization}
                  helperText={errors.organization}
                  disabled={!isEdit}
                  InputProps={{
                    style: { fontSize: mobile ? "13px" : "16px" },
                  }}
                />
                <Sectordrop
                  variant="outlined"
                  spacing="49%"
                  setSector={setSector}
                  sector={sector}
                  small
                  isEdit={isEdit}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <TextField
                  id="web"
                  label="URL"
                  variant="outlined"
                  size="small"
                  sx={{
                    ...(!isEdit ? removeBorder : { flex: 1 }),
                    "& .MuiInputBase-input": {
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: "140px",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      color: "black",
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  value={isEdit ? weblink || "" : weblink || "-"}
                  onChange={(e) => {
                    setWeblink(e.target.value);
                    validate("weblink", e.target.value);
                  }}
                  error={!!errors.weblink}
                  helperText={errors.weblink}
                  disabled={!isEdit}
                  InputProps={{
                    style: { fontSize: mobile ? "13px" : "16px" },
                  }}
                />
                <TextField
                  id="ref"
                  label="Referrer"
                  variant="outlined"
                  size="small"
                  sx={{
                    ...(!isEdit ? removeBorder : { flex: 1 }),
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      color: "black",
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  value={isEdit ? referrer || "" : referrer || "-"}
                  onChange={(e) => {
                    setReferrer(e.target.value);
                    validate("referrer", e.target.value);
                  }}
                  error={!!errors.referrer}
                  helperText={errors.referrer}
                  disabled={!isEdit}
                  InputProps={{
                    style: { fontSize: mobile ? "13px" : "16px" },
                  }}
                />
              </Box>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "black",
                    mt: "12px",
                  }}
                >
                  <Box
                  width='100%'
                    sx={{
                      display: "flex",
                      justifyContent: isEdit? "start":"end",
                      color: "black",
                      alignItems: "center",
                      // marginLeft: isEdit?'inherit':'335px'
                    }}
                  >
                    {!isEdit ? (
                      <IconButton
                        onClick={handleEditKey}
                        sx={{ color: "black" }}
                      >
                        <EditIcon sx={{ marginTop: "3px" }} />
                      </IconButton>
                    ) : null}
                    <DeleteEntry
                      id={props.params?.id as number}
                      setEditOpen={props.setOpen}
                    />
                  </Box>
                  {isEdit ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: "12px",
                          gap: "5px",
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={handleEditKey}
                          autoFocus
                          sx={{
                            color: "white",
                            bgcolor: "#D32F2F",

                            // mx: "auto",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          autoFocus
                          sx={{
                            color: "white",
                            bgcolor: "#1976d2",

                            // mx: "auto",
                          }}
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Box />
                  )}
                </Box>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Drawer>
    </React.Fragment>
  );
}
