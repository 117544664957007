import * as React from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import {
  DataContext,
  GridContext,
  LoadingContext,
} from "../Context/AllContext";
import { headers } from "../Layout/Layout";
import { useSearchParams } from "react-router-dom";
// import { GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid";

export default function DeleteEntry(props: {
  id: number;
  setEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit?:boolean;
}) {
  const { getEntries } = React.useContext(DataContext);
  const { setLoading } = React.useContext(LoadingContext);
  const { getListData } = React.useContext(DataContext);
  const { getGridData } = React.useContext(GridContext);

  const [open, setOpen] = React.useState(false);

  const [searchParams] = useSearchParams();

  const tabFromURL = searchParams.get("tab");

  const handleDelete = () => {
    setLoading(true);
    axios
      .delete(`${process.env.REACT_APP_STRAPIURL}/api/entries/${props.id}`, {
        headers: headers,
      })
      .then((res) => {
        toast.success("Deleted successfully", {
          position: "bottom-left",
        });
        setOpen(false);
        tabFromURL === "1"
          ? getGridData()
          : tabFromURL === "0"
          ? getListData()
          : getEntries();
        setLoading(false);
        props.setEditOpen(false);
      })
      .catch(() => {
        toast.error("Delete Failed", {
          position: "bottom-left",
        });
        setOpen(false);
        setLoading(false);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ fontSize: "small" }}>
        <DeleteIcon
          onClick={handleClickOpen}
          sx={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
            marginTop: props.isEdit ? "0px" : "3px",
          }}
        />
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title"></DialogTitle> */}
        <DialogContent sx={{ padding: "15px" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ padding: "0px" }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              color={"black"}
              gap={"5px"}
            >
              <DeleteIcon /> Are you sure?
            </Box>
          </DialogContentText>
        </DialogContent>
        <Box
          display={"flex"}
          alignContent={"center"}
          justifyContent={"center"}
          gap={"3px"}
          padding={"2px"}
        >
          <DialogActions sx={{ marginBottom: "8px", padding: "0" }}>
            <Button
              onClick={handleDelete}
              sx={{
                width: 64,
                height: 30,
                backgroundColor: "rgba(211,47,47)",
                color: "white",
                fontWeight: 500,
                "&:hover": {
                  backgroundColor: "rgba(211,47,47)",
                },
              }}
              autoFocus
            >
              Yes
            </Button>
            <Button
              onClick={handleClose}
              sx={{
                width: 64,
                height: 30,
                border: "1px solid gray",
                fontWeight: 500,
                "&:hover": {
                  border: "1px solid black",
                },
              }}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
