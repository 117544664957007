import { Avatar, Box, Typography } from "@mui/material";
import { IDataType } from "../exports/interfaces";
import { stringToColor } from "./EntryProfile";
import { stringAvatar } from "./EntryProfile";
import React from "react";
import Entry from "./Entry";
import useIsMobile from "./MobileQuery";
import MobileEntry from "./MobileEntry";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/LocalPhone";

const GridBox = (props: {
  data: IDataType;
  firstName: string;
  lastName: string;
  image?: string;
  avatar: string;
}) => {
  const [Open, setOpen] = React.useState(false);
  const [drawerOpenPhone, setDrawerOpenPhone] = React.useState(false);

  const fullName = `${props.firstName?.toLocaleUpperCase()} ${props.lastName?.toLocaleUpperCase()}`;
  const Image = props.avatar || props.image;

  const handleBoxClick = () => {
    setOpen(true);
    setDrawerOpenPhone(true);
  };

  const mobile = useIsMobile();
  return (
    <>
      <Box
        onClick={handleBoxClick}
        sx={{
          width: "100%",
          height: mobile ? "265px" : "330px",
          bgcolor: "#EBF8F2", //#EBF8F2
          // margin: "15px",
          // marginBottom: mobile ?'5px':'15px',
          // marginLeft: mobile ? "2px" : "15px",
          border: "1px solid #D2FOE3",
          borderRadius: "10px",
          cursor: "pointer",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            {...(!Image && props.firstName && props.lastName
              ? stringAvatar(fullName)
              : {})}
            src={`${process.env.REACT_APP_STRAPIURL}${props?.data?.attributes?.Profile_Image?.data?.attributes?.url}`}
            sx={{
              height: mobile ? "56px" : "64px",
              width: mobile ? "56px" : "64px",
              fontSize: mobile ? "28px" : "32px",
              marginLeft: "12px",
              bgcolor: !Image
                ? stringToColor(props.firstName + " " + props.lastName)
                : "inherit",
            }}
          />

          <Box
            sx={{ display: "flex", flexDirection: "column", margin: "12px" }}
          >
            <Typography sx={{ fontSize: mobile ? "13px" : "16px" }}>
              {props.data.attributes.full_name +
                " " +
                props.data.attributes.last_name}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "end", gap: "5px" }}>
              {props.data.attributes.Phone ? (
                <a
                  href={`tel:${props.data.attributes.Phone}`}
                  style={{ textDecoration: "none" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <PhoneIcon
                    sx={{ height: "18px", width: "18px", cursor: "pointer"}}
                  />
                </a>
              ) : (
                <PhoneIcon sx={{ height: "18px", width: "18px" }}
                onClick={(e) => e.stopPropagation()}
                 />
              )}
              <Typography sx={{ fontSize: mobile ? "13px" : "16px" }}>
                {props.data.attributes.Phone
                  ? props.data.attributes.Phone
                  : "-"}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems:'end', gap: "5px" }}>
              {props.data.attributes.Email ? (
                <a href={`mailto:${props.data.attributes.Email}`} 
                onClick={(e) => e.stopPropagation()}
                >
                  <EmailIcon
                    sx={{ height: "18px", width: "18px", cursor: "pointer" }}
                  />
                </a>
              ) : (
                <EmailIcon sx={{ height: "18px", width: "18px" }} />
              )}
              <Typography sx={{ fontSize: mobile ? "13px" : "16px" }}>
                {props.data.attributes.Email
                  ? props.data.attributes.Email
                  : "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px dotted #AAAAAA",
            marginLeft: "12px",
            marginRight: "12px",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
            marginTop: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: mobile ? "9px" : "14px" }}>
              Subject Area
            </Typography>
            <Typography sx={{ fontSize: mobile ? "11px" : "16px" }}>
              {props.data.attributes.subject_area
                ? props.data.attributes.subject_area
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "12px",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: mobile ? "9px" : "14px" }}>
              Expertise
            </Typography>
            <Typography sx={{ fontSize: mobile ? "11px" : "16px" }}>
              {Array.isArray(props.data.attributes.expertise)
                ? props.data.attributes.expertise.map((value, index) =>
                    index + 1 === props.data.attributes.expertise.length
                      ? value
                      : `${value}, `
                  )
                : props.data.attributes.expertise || "-"}
            </Typography>
          </Box>
        </Box>
        {/* second line */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
            marginTop: "8px",
            bgcolor: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: mobile ? "9px" : "14px" }}>
              Designation
            </Typography>
            <Typography sx={{ fontSize: mobile ? "11px" : "16px" }}>
              {props.data.attributes.Designation
                ? props.data.attributes.Designation
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "12px",
              alignItems: "start",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: mobile ? "9px" : "14px" }}>
              Department
            </Typography>
            <Typography sx={{ fontSize: mobile ? "11px" : "16px" }}>
              {props.data.attributes.Department
                ? props.data.attributes.Department
                : "-"}
            </Typography>
          </Box>
        </Box>
        {/* third line */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
            marginTop: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: mobile ? "9px" : "14px" }}>
              Organization
            </Typography>
            <Typography sx={{ fontSize: mobile ? "11px" : "16px" }}>
              {props.data.attributes.Organization
                ? props.data.attributes.Organization
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "12px",
              alignItems: "start",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: mobile ? "9px" : "14px" }}>
              Sector
            </Typography>
            <Typography sx={{ fontSize: mobile ? "11px" : "16px" }}>
              {props.data.attributes.Sector
                ? props.data.attributes.Sector
                : "-"}
            </Typography>
          </Box>
        </Box>
        {/* fourth line */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
            marginRight: "8px",
            marginTop: "8px",
            bgcolor: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: mobile ? "9px" : "14px" }}>
              URL
            </Typography>
            <Typography
              sx={{
                fontSize: mobile ? "11px" : "16px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: props.data.attributes.Weblink
                  ? mobile
                    ? "120px"
                    : "180px"
                  : "unset",
                whiteSpace: props.data.attributes.Weblink ? "nowrap" : "normal",
              }}
            >
              {props.data.attributes.Weblink
                ? props.data.attributes.Weblink
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "12px",
              // alignItems: "start",
              flex: "1",
            }}
          >
            <Typography sx={{ fontSize: mobile ? "9px" : "14px" }}>
              Referrer
            </Typography>
            <Typography sx={{ fontSize: mobile ? "11px" : "16px" }}>
              {props.data.attributes.Referrer
                ? props.data.attributes.Referrer
                : "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* //for entry form to open onClick */}
      {mobile ? (
        <MobileEntry
          params={props.data}
          open={drawerOpenPhone}
          setOpen={setDrawerOpenPhone}
        />
      ) : (
        <Entry params={props.data} open={Open} setOpen={setOpen} />
      )}
    </>
  );
};

export default GridBox;
