import React, { useEffect, useState, useContext, useRef } from "react";
import { Box, CircularProgress } from "@mui/material";
import { IDataType } from "../exports/interfaces";
import PhoneList from "./PhoneList";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { toast } from "react-toastify";
import { DataContext, StartingIndexContext } from "../Context/AllContext";
import { headers } from "../Layout/Layout";

const PhoneView = () => {
  const { getListData, ListPageSize,listPage,setListPage, setListPageSize, listData } =
    useContext(DataContext);
  const [items, setItems] = useState<IDataType[]>(listData?.data);
  const [hasMore, setHasMore] = useState(true);
  const { startingIndex } = useContext(StartingIndexContext);

  // console.log(data)

  const scrollablePhoneDivRef = useRef<HTMLDivElement>(null);
  const [isScrollDown, setIsScrollDown] = useState(false);

  const scrollToBottom = () => {
    if (scrollablePhoneDivRef.current) {
      scrollablePhoneDivRef.current.scrollTo({
        top: scrollablePhoneDivRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = () => {
    if (scrollablePhoneDivRef.current) {
      scrollablePhoneDivRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const getMoreData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_STRAPIURL
        }/api/entries?sort[0]=sorting_value:asc&populate=Profile_Image&pagination[pageSize]=${
          ListPageSize > 15 ? ListPageSize : 15
        }&pagination[page]=${isScrollDown ? 2 : listPage + 1}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setItems((newItems) => newItems?.concat(res?.data?.data));
        setIsScrollDown(false);
      })
      .catch(() => {
        toast.error("Failed to fetch data", {
          position: "bottom-left",
        });
      });
  };

  useEffect(() => {
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = () => {
    if (items?.length >= listData?.meta?.pagination?.total) {
      setHasMore(false);
      return;
    }

    setListPage((page:number) => page + 1);

    setTimeout(() => {
      getMoreData();
    }, 1000);
  };

  useEffect(() => {
    setItems(listData?.data);
    if(listData?.data?.length >= listData?.meta?.pagination?.total){
      setHasMore(false);
    }else{
      setHasMore(true);
    }
  }, [listData]);

  useEffect(() => {
    if (startingIndex) {
      scrollToTop();
      setListPageSize(startingIndex > 15 ? startingIndex : 15);
      startingIndex > 15 && setIsScrollDown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingIndex]);

  useEffect(() => {
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ListPageSize]);

  useEffect(() => {
    if (isScrollDown && items) {
      scrollToBottom();
    }
  }, [isScrollDown, items]);


  return (
    items && (
      <Box
        id="scrollableDiv"
        ref={scrollablePhoneDivRef}
        sx={{
          gap: "15px",
          paddingTop:"5px",
          height: "calc(100dvh - 93px)",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "1px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
          },
        }}
      >
        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "25px",
              }}
            >
              <CircularProgress />
            </Box>
          }
          scrollableTarget="scrollableDiv"
        >
          {items?.map((item: IDataType, index: number) => (
            <PhoneList
              key={item.id}
              data={item}
              firstName={item?.attributes?.full_name}
              lastName={item?.attributes?.last_name}
              image={item?.attributes?.Profile_Image?.data?.attributes?.url}
              avatar={""}
            />
          ))}
        </InfiniteScroll>
      </Box>
    )
  );
};

export default PhoneView;
