import React, { createContext, useState } from "react";
import { RowData } from "../exports/interfaces";
import { headers } from "../Layout/Layout";
import axios from "axios";
import { toast } from "react-toastify";

export const UserDataContext = createContext<any>(null);
export const DataContext = createContext<any>(null);
export const LoadingContext = createContext<any>(null);
export const StartingIndexContext = createContext<any>(null);
export const GridContext = createContext<any>(null);
export const OcrContext = createContext<any>(null);
export const SamePostContext = createContext<any>(null);

function AllContext({ children }: { children: React.ReactNode }) {
  const [data, setData] = useState<RowData[]>([]);
  const [gridData, setGridData] = useState<RowData[]>([]);
  const [listData, setListData] = useState<RowData[]>([]);
  const [gridPageSize, setGridPageSize] = useState<number>(9);
  const [ListPageSize, setListPageSize] = useState<number>(15);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gridPage, setGridPage] = useState<number>(9);
  const [listPage, setListPage] = useState<number>(15);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [loading, setLoading] = useState<boolean>(false);
  const [startingIndex, setStartingIndex] = useState<number>();


  const [userData, setUserData] = useState();
  const [ocrData, setOcrData] = useState()

  

  const getEntries = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_STRAPIURL
        }/api/entries?sort[0]=sorting_value:asc&populate=Profile_Image&pagination[pageSize]=${pageSize}&pagination[page]=${
          page + 1
        }`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch(() => {
        toast.error("Failed to fetch data", {
          position: "bottom-left",
        });
      });
  };

  const getGridData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_STRAPIURL
        }/api/entries?sort[0]=sorting_value:asc&populate=Profile_Image&pagination[pageSize]=${
          gridPageSize > 9 ? gridPageSize : 9
        }&pagination[page]=1`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setGridData(res.data);
        setGridPage(1);
      })
      .catch(() => {
        toast.error("Failed to fetch data", {
          position: "bottom-left",
        });
      });
  };

  const getListData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_STRAPIURL
        }/api/entries?sort[0]=sorting_value:asc&populate=Profile_Image&pagination[pageSize]=${
          ListPageSize > 15 ? ListPageSize : 15
        }&pagination[page]=1`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setListData(res.data);
        setListPage(1);
      })
      .catch(() => {
        toast.error("Failed to fetch data", {
          position: "bottom-left",
        });
      });
  };

  return (
    <>
      <LoadingContext.Provider value={{ loading, setLoading }}>
        <StartingIndexContext.Provider
          value={{ startingIndex, setStartingIndex }}
        >
          <GridContext.Provider
            value={{
              setGridPage,
              gridData,
              setGridData,
              getGridData,
              gridPage,
              gridPageSize,
              setGridPageSize,
            }}
          >
            <DataContext.Provider
              value={{
                setPageSize,
                pageSize,
                setPage,
                page,
                data,
                setData,
                getEntries,
                getListData,
                ListPageSize,
                setListPageSize,
                listData,
                setListData,
                listPage,
                setListPage,
              }}
            >
              <OcrContext.Provider
                value={{
                  ocrData,
                  setOcrData,
      
                }}
              >
                <UserDataContext.Provider value={{ userData, setUserData }}>
                 
                    {children}
                </UserDataContext.Provider>
              </OcrContext.Provider>
            </DataContext.Provider>
          </GridContext.Provider>
        </StartingIndexContext.Provider>
      </LoadingContext.Provider>
    </>
  );
}

export default AllContext;
