import React from "react";
import Header from "./Header";
import PhoneSlider from "./PhoneSlider";
// import PhoneMenu from "./PhoneMenu";
import { Box } from "@mui/material";

const MobileLayout = (props: {
  gridPage: number;
  gridDataSize: number;
  gridPageSize: number;
  setGridPage: React.Dispatch<React.SetStateAction<number>>;
  setGridPageSize: React.Dispatch<React.SetStateAction<number>>;
  setGridDataSize: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <>
    <Box height='100dvh' display={"flex"} flexDirection={"column"}>
      <Box sx={{ backgroundColor: "#EAF0FF" }}>
        <Header />
      </Box>
      <Box sx={{ backgroundColor: "#EAF0FF" }} flex={1}>
        <PhoneSlider
          gridDataSize={props.gridDataSize}
          setGridDataSize={props.setGridPageSize}
          gridPage={props.gridPage}
          setGridPage={props.setGridPage}
          gridPageSize={props.gridPageSize}
          setGridPageSize={props.setGridPageSize}
          page={props.page} 
          setPage={props.setPage}
        />
        {/* <PhoneMenu  /> */}
      </Box>
      </Box>
    </>
  );
};

export default MobileLayout;
