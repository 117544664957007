import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { Typography } from "@mui/material";
import Entry from "./Entry";
import ProfileImage from "./ProfileText";
import { IDataType } from "../exports/interfaces";
import { DataContext } from "../Context/AllContext";

export default function Table(props: {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  pageSize: number;
  page: number; 
}) {
  const { data } = React.useContext(DataContext);
  const [editOpen, setEditOpen] = React.useState(false);
  const [editInfo, setEditInfo] = React.useState<IDataType>();


  const SimplifiedRows = data.map(
    (item: {
      id: number;
      attributes: {
        full_name: string;
        last_name: string;
        Email: string;
        Phone: string;
      };
    }) => ({
      id: item.id,
      Contact_Name: item.attributes.full_name + " " + item.attributes.last_name,
      Email: item.attributes.Email,
      Phone: item.attributes.Phone,
    })
  );

  const getColumnWidth = (rows: any[], field: string, headerText: string) => {
    const maxLength = Math.max(
      ...rows.map((row) => (row[field] ? row[field].toString().length : 0)),
      headerText.length
    );
    return field === "Contact_Name" ? maxLength * 13 : maxLength * 10; 
  };



  const columns: GridColDef[] = [
    {
      field: "sn",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            SN
          </Typography>
        );
      },
      width:50,
      sortable: false,
      renderCell: (row) => {
        return (
          props.page * props.pageSize +
          row.api.getAllRowIds().indexOf(row.row.id) +
          1
        );
      },
    },
    // {
    //   field: "profile",
    //   renderHeader: () => {
    //     return (
    //       <Typography fontWeight="700" fontSize={12}>
    //         Profile
    //       </Typography>
    //     );
    //   },
    //   minWidth: 60,
    //   flex: 1,
    //   sortable: false,
    //   headerAlign: "left",
    //   align: "left",
    //   renderCell: (row) => {
    //     return (
    //       <ProfileImage
    //         firstName={row.row.attributes.full_name}
    //         lastName={row.row.attributes.last_name}
    //         image={row?.row?.attributes?.Profile_Image?.data}
    //       />
    //     );
    //   },
    // },
    {
      field: "ContactName",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Name
          </Typography>
        );
      },
      // minWidth: 200,
      // flex: 1,
      sortable: false,
      width: getColumnWidth(SimplifiedRows, "Contact_Name", "Name"),
      renderCell: (row) => {
        return (
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <Box>
              <ProfileImage
                firstName={row.row.attributes.full_name}
                lastName={row.row.attributes.last_name}
                image={row?.row?.attributes?.Profile_Image?.data}
              />
            </Box>
            <Box>
              {row.row.attributes.full_name +
                " " +
                row.row.attributes.last_name}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "PhoneNum",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Phone
          </Typography>
        );
      },
      sortable: false,
      // minWidth: 200,
      headerAlign: "left",
      align: "left",
      // flex: 1,
      width: getColumnWidth(SimplifiedRows, "Phone", "Phone"),
      renderCell: (row) => {
        return row.row.attributes.Phone;
      },
    },
    {
      field: "email",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Email
          </Typography>
        );
      },
      sortable: false,
      width: getColumnWidth(SimplifiedRows, "Email", "Email"),
      // minWidth: 200,
      // flex: 1,
      renderCell: (row) => {
        return row.row.attributes.Email ? row.row.attributes.Email : "-";
      },
    },

    // {
    //   field: "SubjectArea",
    //   renderHeader: () => {
    //     return (
    //       <Typography fontWeight="700" fontSize={12}>
    //         Subject Area
    //       </Typography>
    //     );
    //   },
    //   minWidth: 97,
    //   headerAlign: "left",
    //   align: "left",
    //   flex: 1,
    //   sortable: false,
    //   renderCell: (row) => {
    //     return row.row.attributes.subject_area;
    //   },
    // },
    // {
    //   field: "Expertise",
    //   renderHeader: () => {
    //     return (
    //       <Typography fontWeight="700" fontSize={12}>
    //         Expertise
    //       </Typography>
    //     );
    //   },
    //   type: "number",
    //   minWidth: 80,
    //   headerAlign: "left",
    //   align: "left",
    //   sortable: false,
    //   flex: 1,
    //   renderCell: (row) => {
    //     return Array.isArray(row.row.attributes.expertise)
    //       ? row?.row?.attributes?.expertise?.map(
    //           (value: string) => `${value}, `
    //         )
    //       : "-";
    //   },
    // },
  ];



  return (
    <>
      <Box sx={{ height: "calc(100dvh - 113px)", overflow: "hidden" }}>
        <DataGrid
          hideFooterPagination={true}
          rows={data}
          columns={columns}
          apiRef={props.apiRef}
          scrollbarSize={0}
          disableRowSelectionOnClick
          disableColumnMenu
          onRowClick={(params) => {
            setEditOpen(true);
            setEditInfo(params.row);
          }}
          sx={{
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none", // Removes the focus outline
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-scrollbar--vertical": {
              "&::-webkit-scrollbar": {
                display: "none",
              },
            },
            "& .MuiDataGrid-scrollbar--horizontal": {
              "&::-webkit-scrollbar": {
                height: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "1px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1",
              },
            },
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
            "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
              borderLeft: "none", 
            },
          }}
        />
      </Box>

      <Entry params={editInfo} open={editOpen} setOpen={setEditOpen} />
    </>
  );
}
