import { Box, Button } from "@mui/material";
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import { headers } from "../Layout/Layout";
import { toast } from "react-toastify";
import Ocr from "./Ocr";
import { LoadingContext, UserDataContext } from "../Context/AllContext";
import useIsMobile from "./MobileQuery";

interface IOcrData {
  uploaded_by: string | null;
  date: string | null;
  photo?: number;
}

const OcrImage = (props: {
  setOcrData?: Dispatch<SetStateAction<FormData | undefined>>;
  isOcrDataUploaded?: Boolean;
}) => {
  const { setLoading } = React.useContext(LoadingContext);
  const { userData } = React.useContext(UserDataContext);
  const [newImage, setNewImage] = useState<File | null>(null);
  const [imageView, setImageView] = useState<string | null>(null);

  const getDate = (): string => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1);
    const day = String(today.getDate());
    return `${year}-${month}-${day}`;
  };

  const createOcr = (formData: IOcrData) => {
    axios
      .post(
        `${process.env.REACT_APP_STRAPIURL}/api/ocr-photos`,
        { data: formData },
        { headers: headers }
      )
      .then(() => {
        toast.success("Success", {
          position: "bottom-left",
        });
        setLoading(false);
      });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setNewImage(file);
      setImageView(URL.createObjectURL(file));
    }
  };

  const handleCameraFile = (file: File) => {
    setNewImage(file);
    setImageView(URL.createObjectURL(file));
  };

  // useEffect(() => {
  //   props.setOcrData && props.setOcrData(ocrImage);
  // }, [ocrImage]);

  const addNewImage = () => {
    //use ocrImage in datform .then
    const ocrImage = new FormData();
    if (newImage) {
      ocrImage.append("files", newImage);
      ocrImage.append("ref", "entry");
      ocrImage.append("field", "photo");
    }

    const formData: IOcrData = {
      uploaded_by: userData?.name,
      date: getDate(),
    };

    if (newImage) {
      axios
        .post(`${process.env.REACT_APP_STRAPIURL}/api/upload`, ocrImage, {
          headers: headers,
        })
        .then((res) => {
          formData.photo = res.data[0].id;
          createOcr(formData);
          setImageView(null);
        });
    } else {
      createOcr(formData);
    }
  };

  const mobile = useIsMobile();

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
          gap: "10px",
          margin: mobile ? "10px" : "15px",
        }}
      >
        <Box>
          <AttachFileIcon />
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ cursor: "pointer" }}
          />

          {imageView && (
            <Box
              component="img"
              src={imageView}
              sx={{
                display: "flex",
                width: "400px",
                height: "200px",
                marginTop: "15px",
              }}
            />
          )}
          <Box
            sx={{
              display: "flex",
              marginTop: "15px",
              marginLeft: mobile ? "250px" : "280px",
            }}
          >
            <Ocr onFileSelected={handleCameraFile} />
            <Button
              onMouseDown={addNewImage}
              onKeyDown={addNewImage}
              sx={{
                backgroundColor: "#0288D1",
                color: "white",
                "&:hover": {
                  backgroundColor: "#0288D1",
                },
              }}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default OcrImage;
