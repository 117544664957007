import React, { Dispatch, SetStateAction, useContext, useEffect } from "react";
import Menu from "../Components/Menu";
import Header from "../Components/Header";
// import Alphabet from "../Components/Alphabet";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Slider from "../Components/Slider";
import { LoadingContext } from "../Context/AllContext";

export const headers = {
  Authorization: `Bearer ${process.env.REACT_APP_STRAPITOKEN}`,
};

const Layout = (props:{
  setPage: React.Dispatch<React.SetStateAction<number>>;
  gridPageSize: number;
  // setGridDataSize: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  gridPage:number;
  setGridPage:React.Dispatch<React.SetStateAction<number>>;
  setGridPageSize: Dispatch<SetStateAction<number>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  startingIndex: any;
}) => {
  // const {setData } = React.useContext(DataContext);
  // const {setGridData} = React.useContext(GridContext)
  const {loading}=useContext(LoadingContext);

  
  
  const getStartingPage = () => {
    if (props.startingIndex) {
      props.setPage(~~(props.startingIndex / props.pageSize));
    }
  };

  useEffect(() => {
    if (props.startingIndex) {
      getStartingPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startingIndex, props.pageSize]);

  return (
    <>
     
         
              <div className="main">
                <Menu />
                <div className="top">
                  <Header />
                  {/* <Alphabet /> */}
                  <Slider
                    page={props.page}
                    pageSize={props.pageSize}
                    setPage={props.setPage}
                    setPageSize={props.setPageSize}
                    gridPage={props.gridPage}
                    gridPageSize={props.gridPageSize}
                    setGridPage={props.setGridPage}
                    setGridPageSize={props.setGridPageSize}
                  />
                </div>
              </div>
        

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Layout;
