import "./App.css";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Components/theme/Theme";
import { ToastContainer } from "react-toastify";
import Main from "./routes/Main";
import AllContext from "./Context/AllContext";

function App(){
  return (
    <>
      <ThemeProvider theme={theme}>
        <AllContext>
          <Main/>
        </AllContext>
      </ThemeProvider>
      <ToastContainer />
    </>
  );
}

export default App;
