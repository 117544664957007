import "react-international-phone/style.css";
import PhoneIcon from "@mui/icons-material/LocalPhone";
import {
  BaseTextFieldProps,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import useIsMobile from "./MobileQuery";

export interface MUIPhoneProps extends BaseTextFieldProps {
  value: string;
  onChange: (phone: string) => void;
  validate: (name: string, value: string) => void;
  isEdit?: boolean;
}

export const MobileCode: React.FC<MUIPhoneProps> = ({
  value,
  onChange,
  validate,
  isEdit = false,
  ...restProps
}) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "np",
      value,
      countries: defaultCountries,
      onChange: (data) => {
        onChange(
          data.country.name === "Nepal"
            ? data.phone.split("").length > 7
              ? `+${country.dialCode} ${data.inputValue
                  .split(" ")[1]
                  .slice(0, 3)}-${data.inputValue.split(" ")[1].slice(3)}`
              : data.inputValue
            : data.inputValue
        );
      },
    });
  const mobile = useIsMobile();

  return (
    <TextField
      variant="outlined"
      label="Mobile"
      color="primary"
      size="small"
      sx={{ width: "100%" }}
      placeholder="Mobile number"
      value={inputValue}
      onChange={(e) => {
        handlePhoneValueChange(e);
        validate("phone", e.target.value);
      }}
      type="tel"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: "2px", marginLeft: "-5px" }}
          >
            {isEdit ? (
              <Select
                MenuProps={{
                  style: {
                    height: "300px",
                    width: "360px",
                    top: "10px",
                    left: "-34px",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                sx={{
                  width: "max-content",
                  fieldset: {
                    display: "none",
                  },
                  ".MuiSelect-select": {
                    padding: "8px",
                    paddingRight: "24px !important",
                  },
                  svg: {
                    display: isEdit ? "block" : "none",
                  },
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value as CountryIso2)}
                renderValue={(value) => (
                  <FlagImage iso2={value} style={{ display: "flex" }} />
                )}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagImage
                        iso2={country.iso2}
                        style={{ marginRight: "8px" }}
                      />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="gray">+{country.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            ) : (
              <FlagImage iso2={country.iso2} style={{ display: "flex" }} />
            )}
          </InputAdornment>
        ),
        endAdornment: !isEdit && (
          <InputAdornment
            position="start"
            style={{ marginLeft: "-33px", marginRight: "-10px" }}
          >
            <a href={`tel:${inputValue}`} style={{ textDecoration: "none" }}>
              <PhoneIcon
                sx={{ height: "18px", width: "18px", cursor: "pointer" }}
              />
            </a>
          </InputAdornment>
        ),
        style: { fontSize: mobile ? "13px" : "16px", height: "40px" },
      }}
      {...restProps}
    />
  );
};
