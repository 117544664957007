import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Dataform from "./Dataform";
import OcrImage from "./OcrImage";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs(props: { handleClose: () => void }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // const [ocrData, setOcrData] = React.useState<FormData>();

  const [isOcrDataUploaded, setIsOcrDataUploaded] = React.useState<Boolean>(false);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          indicatorColor="primary"
        >
          <Tab label="Form" {...a11yProps(0)} />
          <Tab label="DOCS" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CustomTabPanel value={value} index={0}>
          <Dataform handleClose={props.handleClose} setIsOcrDataUploaded = {setIsOcrDataUploaded} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <OcrImage isOcrDataUploaded = {isOcrDataUploaded} />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <AddCircleIcon
        onClick={handleClickOpen}
        sx={{ height: "28px", width: "28px" }}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            marginTop: "4px",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <BasicTabs handleClose={handleClose} />
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} sx={{color: 'black', }}>
            Submit
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </React.Fragment>
  );
}
