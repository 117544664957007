import React, { useContext, useEffect, useRef, useState } from "react";
import GridBox from "./GridBox";
import { IDataType } from "../exports/interfaces";
import { Box, CircularProgress } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { toast } from "react-toastify";
import { GridContext, StartingIndexContext } from "../Context/AllContext";
import { headers } from "../Layout/Layout";
import useIsMobile from "./MobileQuery";

const GridView = (props: {
  gridPageSize: number;
  setGridDataSize: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { gridData, getGridData } = useContext(GridContext);
  const [items, setItems] = useState<IDataType[]>(gridData?.data);
  const [hasMore, setHasMore] = useState(true);
  const { startingIndex } = useContext(StartingIndexContext);
  // const [gridDataSize, setGridDataSize] = useState<number>(9);
  // const [page, setPage] = useState<number>(1);
  // const [startIndex, setStartIndex] = useState<number>();

  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const [isScrollBottom, setIsScrollBottom] = useState(false);

  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: scrollableDivRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const getMoreGridData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_STRAPIURL
        }/api/entries?sort[0]=sorting_value:asc&populate=Profile_Image&pagination[pageSize]=${
          props.gridPageSize > 9 ? props.gridPageSize : 9
        }&pagination[page]=${isScrollBottom ? 2 : props.page + 1}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setItems((newItems) => newItems?.concat(res?.data?.data));
        setIsScrollBottom(false);
      })
      .catch(() => {
        toast.error("Failed to fetch data", {
          position: "bottom-left",
        });
      });
  };

  useEffect(() => {
    getGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = () => {
    if (items?.length >= gridData?.meta?.pagination?.total) {
      setHasMore(false);
      return;
    }

    props.setPage((page) => page + 1);

    setTimeout(() => {
      getMoreGridData();
    }, 1000);
  };

  useEffect(() => {
    setItems(gridData?.data);
    if (gridData?.data?.length >= gridData?.meta?.pagination?.total) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [gridData]);

  useEffect(() => {
    if (startingIndex) {
      // setItems([])
      scrollToTop();
      props.setGridDataSize(startingIndex > 9 ? startingIndex : 9);
      startingIndex > 9 && setIsScrollBottom(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingIndex]);

  useEffect(() => {
    getGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gridPageSize]);

  useEffect(() => {
    if (isScrollBottom && items) {
      scrollToBottom();
    }
  }, [isScrollBottom, items]);

  const itemsPerRow = 3;

  const numPlaceholders =
    items?.length % itemsPerRow !== 0
      ? itemsPerRow - (items?.length % itemsPerRow)
      : 0;

  const mobile = useIsMobile();

  return (
    items && (
      <Box
        id="scrollableDiv"
        ref={scrollableDivRef}
        sx={{
          height: "calc(100vh - 93px)",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "1px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
          },
          "&::-webkit-scrollbar-button": {
            display: "none",
          },
        }}
      >
        <InfiniteScroll
          dataLength={items?.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            hasMore && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "25px",
                  flexWrap: "wrap",
                }}
              >
                <CircularProgress />
              </Box>
            )
          }
          scrollableTarget="scrollableDiv"
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: mobile
                ? "repeat(1, minmax(0, 1fr))"
                : `repeat(${itemsPerRow}, minmax(0, 1fr))`, // 1 item per row on mobile
              gap: "15px",
              padding: "15px",
              marginRight: mobile ? "15px" : 0,
              marginBottom: mobile ? 'inherit':'35px'
            }}
          >
            {items?.map((item: IDataType) => (
              <Box
                key={item.id}
                sx={{
                  boxSizing: "border-box",
                }}
              >
                <GridBox
                  data={item}
                  firstName={item?.attributes?.full_name}
                  lastName={item?.attributes?.last_name}
                  image={item?.attributes?.Profile_Image?.data?.attributes?.url}
                  avatar={""}
                />
              </Box>
            ))}
            {Array.from({ length: numPlaceholders }).map((_, index) => (
              <Box
                key={`placeholder-${index}`}
                sx={{
                  boxSizing: "border-box",
                  visibility: "hidden",
                }}
              />
            ))}
          </Box>
        </InfiniteScroll>
      </Box>
    )
  );
};

export default GridView;
