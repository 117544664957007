import React, { useContext } from "react";
import { IconButton } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import axios from "axios";
import { OcrContext } from "../Context/AllContext";

interface OcrProps {
  onFileSelected: (file: File) => void; // Add a callback prop to pass the file
}

const Ocr: React.FC<OcrProps> = ({onFileSelected}) => {
  const { setOcrData } = useContext(OcrContext);

  // Handle file selection (camera or gallery)
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      onFileSelected(file);

      
      const reader = new FileReader();
      reader.onload = async () => {
        const imageDataUrl = reader.result as string;

        // Send the image to the OCR API
        try {
          const response = await axios.post("http://localhost:5000/api/ocr", {
            image: imageDataUrl,
          });
          if (response.data) {
            setOcrData(response.data); 
          }
        } catch (error) {
          console.error("Error sending image to OCR API:", error);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
   
      <input
        type="file"
        accept="image/*"
        capture="environment" 
        id="file-input"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />

    
      <IconButton onClick={() => document.getElementById("file-input")?.click()}>
        <CameraAltIcon sx={{ width: "28px", height: "28px" }} />
      </IconButton>
    </div>
  );
};

export default Ocr;
