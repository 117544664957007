import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import {
  Autocomplete,
  Button,
  IconButton,
  Paper,
  Popper,
  styled,
  TextField,
  TextFieldVariants,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { headers } from "../Layout/Layout";
import { removeBorder } from "./Entry";
import useIsMobile from "./MobileQuery";

interface ISubjectArea {
  attributes: {
    subject_area: string;
  };
  id: number;
}

export default function ComboBox(props: {
  variant: TextFieldVariants;
  small?: boolean;
  spacing: string;
  subject?: string;
  setSubject: Dispatch<SetStateAction<string>>;
  isEdit?: boolean;
}) {
  const [subjectAreas, setSubjectAreas] = useState<ISubjectArea[]>([]);
  const [newValue, setNewValue] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [subjectToDelete, setSubjectToDelete] = useState<ISubjectArea | null>(
    null
  );

  const getSubjectAreas = () => {
    axios
      .get(`${process.env.REACT_APP_STRAPIURL}/api/subject-areas`, {
        headers: headers,
      })
      .then((response) => setSubjectAreas(response.data.data))
      .catch(() =>
        toast.error("Error fetching data", {
          position: "bottom-left",
        })
      );
  };

  useEffect(() => {
    getSubjectAreas();
  }, []);

  const addNewValue = () => {
    if (newValue) {
      axios
        .post(
          `${process.env.REACT_APP_STRAPIURL}/api/subject-areas`,
          { data: { subject_area: newValue } },
          { headers: headers }
        )
        .then(() => {
          getSubjectAreas();
          toast.success("Subject added successfully", {
            position: "bottom-left",
          });
        })
        .catch((error) => {
          toast.error("Failed to add subject", {
            position: "bottom-left",
          });
        });
      setNewValue(undefined);
    }
  };

  const handleDelete = () => {
    if (subjectToDelete) {
      axios
        .delete(
          `${process.env.REACT_APP_STRAPIURL}/api/subject-areas/${subjectToDelete.id}`,
          {
            headers: headers,
          }
        )
        .then(() => {
          getSubjectAreas();
          toast.success("Subject deleted successfully", {
            position: "bottom-left",
          });
        })
        .catch(() => {
          toast.error("Failed to delete subject", {
            position: "bottom-left",
          });
        })
        .finally(() => {
          setOpen(false);
          setSubjectToDelete(null);
        });
    }
  };

  const handleOpenDialog = (subject: ISubjectArea) => {
    setSubjectToDelete(subject);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSubjectToDelete(null);
  };

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addNewValue();
    }
  };

  const CustomPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      maxHeight: "175px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "1px",
      },
    },
  });

  const mobile = useIsMobile();

  return (
    <>
      <Autocomplete
        disablePortal
        disabled={!props.isEdit}
        id="combo-box-demo"
        size={props.small === true ? "small" : "medium"}
        options={subjectAreas}
        getOptionLabel={(option) => option.attributes.subject_area}
        onChange={(e, value) => {
          if (value !== null) {
            props.setSubject(value.attributes.subject_area);
          }
        }}
        onInputChange={(event, value, reason) => {
          if (reason === "clear") {
            props.setSubject("");
          }
        }}
        value={
          subjectAreas.find(
            (area) => area.attributes.subject_area.toLowerCase() === props.subject?.toLowerCase()
          ) ||
          (!props.isEdit ? { attributes: { subject_area: "-" }, id: 0 } : null)
        }
        sx={{ width: props.spacing }}
        PopperComponent={(props) => <CustomPopper {...props} />}
        renderInput={(params) => (
          <TextField
            onChange={(e) => setNewValue(e.target.value)}
            onKeyDown={handleKey}
            // value={props.isEdit ? props.subject|| "" : props.subject|| "-"}
            variant={props.variant}
            sx={{
              ...(!props.isEdit ? removeBorder : { flex: 1 }),
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
                color: "black",
              },
              "& .MuiInputLabel-root.Mui-disabled": {
                color: "black",
              },
              "& .MuiAutocomplete-endAdornment": {
                display: props.isEdit ? "flex" : "none",
              },
            }}
            {...params}
            label="Subject"
            InputProps={{
              style: { fontSize: mobile ? "13px" : "16px" },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>{option.attributes.subject_area}</span>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenDialog(option);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </li>
        )}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              width: "200px !important",
            }}
          >
            {children}
            <Button
              color="primary"
              fullWidth
              sx={{ justifyContent: "flex-start", pl: 2 }}
              onMouseDown={addNewValue}
              onKeyDown={addNewValue}
            >
              + Add New
            </Button>
          </Paper>
        )}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the subject "
            {subjectToDelete?.attributes.subject_area}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} sx={{ color: "red" }} autoFocus>
            Yes
          </Button>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
