import React, { useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { IDataType } from "../exports/interfaces";
import { stringAvatar, stringToColor } from "./EntryProfile";
import MobileEntry from "./MobileEntry";

const PhoneList = (props: {
  data: IDataType;
  firstName: string;
  lastName: string;
  image?: string;
  avatar: string;
}) => {
  const fullName = `${props.firstName?.toLocaleUpperCase()} ${props.lastName?.toLocaleUpperCase()}`;
  const Image = props.avatar || props.image;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "85%",
          marginTop: "10px",
          marginLeft: "20px",
          display: "flex",
          alignContent: "center",
          gap: "15px",
        }}
        onClick={handleOpenDrawer}
      >
        <Avatar
          {...(!Image && props.firstName && props.lastName
            ? stringAvatar(fullName)
            : {})}
          src={`${process.env.REACT_APP_STRAPIURL}${props?.data?.attributes?.Profile_Image?.data?.attributes?.url}`}
          sx={{
            height: "48px",
            width: "48px",
            fontSize: "24px",
            marginLeft: "12px",
            bgcolor: !Image
              ? stringToColor(props.firstName + " " + props.lastName)
              : "inherit",
          }}
        />
        <Box
          sx={{
            display: "flex", 
            flexDirection: "column",
            margin: "5px",
            top: "50%",
          }}
        >
          <Typography sx={{ fontSize: "18px" }}>
            {props.data.attributes.full_name +
              " " +
              props.data.attributes.last_name}
          </Typography>
          <Typography sx={{ fontSize: "13px" }}>
            {props.data.attributes.Email ? props.data.attributes.Email : "-"}
          </Typography>
        </Box>

        <a
          href={`tel:${props.data.attributes.Phone}`}
          onClick={(e) => e.stopPropagation()}
        >
          <LocalPhoneIcon
            sx={{
              position: "absolute",
              height: "24px",
              width: "24px",
              right: "0",
              top: "50%",
              transform: "translateY(-60%)",
              cursor: "pointer",
              marginRight: "10px",
            }}
          />
        </a>
      </Box>

      <Box
        sx={{
          border: "1px dotted #AAAAAA",
          marginLeft: "12px",
          marginRight: "45px",
          marginTop: "5px",
          marginBottom: "15px",
        }}
      ></Box>

      <MobileEntry
        params={props.data}
        open={drawerOpen}
        setOpen={setDrawerOpen}
      />
    </>
  );
};

export default PhoneList;
