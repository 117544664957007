import { BrowserRouter, Route, Routes } from "react-router-dom";
import CallbackHandler from "../Components/CallbackHandler";
import { useContext, useEffect } from "react";
import axios from "axios";
import LoginGuard from "../services/LoginGuard";
import { DataContext, GridContext, StartingIndexContext, UserDataContext } from "../Context/AllContext";
import useIsMobile from "../Components/MobileQuery";
import MobileLayout from "../Components/MobileLayout";
import React from "react";
import Layout from "../Layout/Layout";
// import Slider from "../Components/Slider";

const Main = (props:{
  
}) => {
  const accessToken = localStorage.getItem("access_token");  
  const { setUserData } = useContext(UserDataContext);
  const {getEntries}= useContext(DataContext)
  const {pageSize}= useContext(DataContext)
  const {setPageSize}= useContext(DataContext)
  const {setPage}= useContext(DataContext)
  const {page}= useContext(DataContext)
  const {gridPageSize}= useContext(GridContext)
  const {setGridPageSize}= useContext(GridContext)
  const {gridPage}= useContext(GridContext)
  const {setGridPage}= useContext(GridContext)
  const {startingIndex}= useContext(StartingIndexContext)

  useEffect(() => {
    if (!accessToken || accessToken === null) {
      return;
    } else {
      axios
        .get(`${process.env.REACT_APP_SISAPI}/api/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => setUserData(res.data))
        .catch(
          () => (window.location.href = process.env.REACT_APP_SISURL as string)
        );
    }
  }, [accessToken, setUserData]);

  const mobile = useIsMobile();



  React.useEffect(() => {
    getEntries();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  return (
    <>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <LoginGuard>
                    {/* <Slider /> */}
                    {mobile ? (
                      <MobileLayout
                        gridDataSize={gridPageSize}
                        setGridDataSize={setGridPageSize}
                        gridPage={gridPage}  
                        setGridPage={setGridPage} 
                        gridPageSize={gridPageSize} 
                        setGridPageSize={setGridPageSize}
                        page={page}
                        setPage={setPage}
                      />
                    ) : (
                      <Layout
                        setPage={setPage}
                        page={page}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        gridPage={gridPage}
                        gridPageSize={gridPageSize}
                        setGridPage={setGridPage}
                        setGridPageSize={setGridPageSize}
                        startingIndex={startingIndex}
                      />
                    )}
                  </LoginGuard>
                }
              />
              <Route path="/callback" element={<CallbackHandler />} />
            </Routes>
          </BrowserRouter>
       
    </>
  );
};

export default Main;
